import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export const cfTopStripToTopStripProps = cfTopStrip => {
  var _cfTopStrip$foregroun;
  return _objectSpread(_objectSpread(_objectSpread({
    actionText: String(cfTopStrip.actionText),
    foregroundImage: String((_cfTopStrip$foregroun = cfTopStrip.foregroundImage) === null || _cfTopStrip$foregroun === void 0 ? void 0 : _cfTopStrip$foregroun.url),
    shortDescription: String(cfTopStrip.shortDescription),
    url: String(cfTopStrip.url)
  }, cfTopStrip.backgroundImage ? {
    backgroundImage: String(cfTopStrip.backgroundImage.url)
  } : undefined), cfTopStrip.backgroundColour ? {
    backgroundColour: String(cfTopStrip.backgroundColour)
  } : undefined), {}, {
    backendTitle: String(cfTopStrip.backendTitle)
  });
};